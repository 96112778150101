.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  height: 100vh;
}


.video-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}

#videoElement {
  border: 2px solid #333;
  border-radius: 10px;
}

.control-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
}

.control-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s ease;
}

.control-buttons button:hover {
  background-color: #0056b3;
}

.chat-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.chat-container input {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex-grow: 1;
}

.chat-container button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #aaa !important;
}

.chat-container button:disabled {
  background-color: #aaa;
}

.s3-list-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.s3-list {
  flex: 1;
  max-width: 332px;
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: auto;
  height: 300px;
  margin-right: 20px;
}

.s3-list li {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.s3-list button {
  padding: 4px;
  font-size: 14px;
  height: 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s ease;
}

.s3-list h2 {
  margin-top: 0;
  text-align: center;
}

.s3-list ul {
  list-style-type: none;
  padding: 0;
}

.s3-list li {
  background-color: #e9ecef;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.s3-list li:hover {
  background-color: #d1e7dd;
}

.chat-history {
  position: absolute;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  overflow-y: auto;
  bottom: 0;
}
.user {
  text-align: right;
}


.chat-history p {
  background-color: #00000009;
  padding: 10px;
}

.sound-bar-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 30px;
  width: 100%;
}

.sound-bar {
  width: 1%;
  border-radius: 20px;
  background: gray;
  margin: 10px 2px;
  transition: height 0.1s;
}
